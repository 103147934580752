@charset "utf-8";

@media (max-width: 1200px){
.container{width: 1024px;}

.header{
     .logo{
          h1{font-size: 2.3em;}
          h2{font-size: 1.4em;}
     }
}

.banner{height: 390px;}

.index-wrapper{
     .section-box2{
          .content{width: 85%;}
     }
}

.inside-left{
     h3{
          padding-left: 20px;
          img{margin-right: 20px;}
     }
     ul{
          li{
               a{
                    padding-left: 45px;
                    &:before{left: 20px;}
               }
          }
     }
}

.list-product{
     .item{
          .pic{height: 240px;}
     }
}
}

@media (max-width:1024px){
.container{width: 900px;}

.index-wrapper{
     .section-box1{
          .slide-wrapper{
               .button-prev{left: -60px;}
               .button-next{right: -60px;}
          }
     }
     .section-box2{
          .content{
               width: 88%;
               .text{
                    line-height: 2;
                    font-size: 14px;
               }
          }
     }
     .section-box4{
          .room-pic-slide{
               height: 310px;
               .pic-info{
                    .title{font-size: 20px;}
                    .text{}
               }
          }
     }
}

.text-list{
     margin-top: -15px;
     .item{
          padding-top: 15px;
          padding-bottom: 15px;
     }
     .date{b{font-size: 26px;}}
     .info{width: 75%;}
     .text{
          margin-top: 15px;
     }
}

.inside-left{width: 25%;}
.inside-right{
     width: 70%;
}

.list-product{
     .item{
          width: 50%;
     }
}

.list-news{margin-top: 10px;}
}

@media (max-width:768px){
.container{width: 768px;}

.header{
     .logo{
          height: 100px;
          img{
               float: none;
               width: 200px;
          }
          h1{display: none;}
          h2{display: none;}
     }
     .search{
          padding-top: (100px-35)/2;
     }
}

.banner{height: 280px;}

.index-wrapper{
     .section-box1{
          .slide-wrapper{
               padding-left: 50px;
               padding-right: 50px;
               .button-prev{left: 0;}
               .button-next{right: 0;}
          }
          .video-center-slide{
               .swiper-slide{
                    .title{line-height: 50px;}
               }
          }
     }
     .section-box2{
          .box-title{
               img{height: 30px;}
          }
          .content{
               width: calc(100% - 20px);
          }
     }
     .section-box3{
          .tab-nav{
               a{
                    height: 40px;
                    line-height: 40px;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 15px;
               }
          }
          .tab-slide{
               padding-left: 40px;
               padding-right: 40px;
               .one-more{
                    top: -(30+40px);
                    height: 40px;
                    line-height: 40px;
               }
          }
          .pro-slide{
               .button-prev{left: -45px;}
               .button-next{right: -45px;}
          }
     }
}

.inside-banner{height: 240px;}

.inside-left{
     width: 28%;
}
.inside-right{
     width: 68%;
}

.footer{
     .nav{a{margin-left: 15px;margin-right: 15px;}}
     .contact{
          .item{
               width: 50%;
               p{font-size: 14px;}
               &:last-child{
                    float: none;
                    clear: both;
                    width: 100%;
                    border-top: none;
               }
          }
     }
}
}

@media (max-width:576px) {
.container{width: 100%;}
.navbtn{
     display:block;
     top:(50px-30+2)/2;
     right:10px;
     padding:0 5px;
     border-radius:4px;
     span{
          width:25px;
          height:2px;
          margin:6px 0;
     }
     &.navclose{
          span{
               &:nth-child(1){
                    -moz-transform:translateY(8px) rotate(45deg);
                    -ms-transform:translateY(8px) rotate(45deg);
                    -webkit-transform:translateY(8px) rotate(45deg);
                    transform:translateY(8px) rotate(45deg);
               }
               &:nth-child(3){
                    -moz-transform:translateY(-8px) rotate(-45deg);
                    -ms-transform:translateY(-8px) rotate(-45deg);
                    -webkit-transform:translateY(-8px) rotate(-45deg);
                    transform:translateY(-8px) rotate(-45deg);
               }
          }
     }
}

.header{
     height: auto;
     border-top-width:2px;
     padding-bottom: 10px;
     .logo{
          float: none;
          width: auto;
          height: 50px;
          a{
               display: block;
               height: 50px;
          }
          img{
               width: auto;
               padding:11px 0;
               margin-right: 0;
          }
     }
     .search{
          float: none;
          width: 100%;
          padding-top: 5px;
          .text{
               width: 90%;
          }
          .submit{
               width: 10%;
          }
     }
}

.navbar{
     float: none;
     width: 100%;
     .nav{
          display: none;
          width: 100%;
          height: auto;
          margin-left: 0;
          margin-right: 0;
          padding-bottom: 10px;
          a{
               float: none;
               width: 100%;
               line-height: 45px;
               border-width: 2px;
          }
     }
}

.banner{height: 150px;}

.index-wrapper{
     padding-bottom: 60px;
     .box-title{
          h3{font-size: 20px;}
          .en{
               height: 15px;
               line-height: 15px;
               margin-top: 5px;
               font-size: 13px;
               &:before,&:after{
                    top: (15px-1)/2;
                    width: 25px;
                    height: 1px;
               }
               &:before{left: -30px;}
               &:after{right: -30px;}
          }
     }
     .section-box1{
          .slide-wrapper{
               margin-top: 30px;
               padding-left: 35px;
               padding-right: 35px;
               .button-prev,.button-next{
                    width: 25px;
                    height: 25px;
               }
          }
          .video-center-slide{
               .swiper-slide{
                    .title{
                         height: 40px;
                         line-height: 40px;
                         font-size: 14px;
                         border-width: 1px;
                    }
               }
          }
          .more{
               width: 120px;
               line-height: 30px;
               margin-top: 30px;
               border-width: 1px;
               font-size: 14px;
          }
     }
     .section-box2{
          .box-title{
               padding-top: 30px;
               img{height: 13px;}
               h3{font-size: 20px;}
          }
          .fl,.fr{
               float: none;
               width: 100%;
               height: auto;
               padding-left: 10px;
               padding-right: 10px;
               padding-top: 60px;
               padding-bottom: 30px;
          }
          .fr{
               padding-top: 30px;
               padding-bottom: 80px;
               .content{
                    .text{margin-top: 0;}
               }
          }
          .content{
               float: none !important;
               width: 100%;
               margin-left: auto;
               margin-right: auto;
               .text{
                    height: auto;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 13px;
                    &:before{display: none;}
               }
               .more{
                    position: absolute;
                    bottom: 15px;
                    left: 0;
                    right: 0;
                    margin:auto;
                    width: 120px;
                    border-color: #fff;
                    background-color: #fff;
                    color:#666;
                    font-size: 14px;
               }
          }
     }
     .section-box3{
          padding-bottom: 20px;
          overflow: hidden;
          .tab-nav{
               width: 100%;
               white-space: nowrap;
               overflow-x: scroll;
               a{
                    float: none;
                    display: inline-block;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
               }
          }
          .tab-slide{
               padding-bottom: 60px;
               .one-more{
                    top: auto;
                    bottom:-60px;
                    left: 0;
                    right: 0;
                    margin:auto;
                    width: 120px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    border:1px #666 solid;
                    font-size: 14px;
               }
          }
          .pro-slide{
               .info{
                    padding-bottom: 15px;
                    .xh{
                         padding-top: 15px;
                         font-size: 14px;
                    }
                    .gn{
                         padding-top: 5px;
                         font-size: 12px;
                    }
                    .more{display: none;}
               }
          }
     }
     .section-box4{
          .tab-nav{
               height: 40px;
               margin-top: 30px;
               a{
                    line-height: 40px;
                    font-size: 16px;
               }
          }
          .tab-slide{
               margin-top: 20px;
               padding-bottom: 60px;
               .one-more{
                    top: auto;
                    bottom:-60px;
                    left: 0;
                    right: 0;
                    margin:auto;
                    width: 120px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    border:1px #666 solid;
                    font-size: 14px;
               }
          }
          .room-pic-slide{
               float: none;
               width: 100%;
               .pic-info{
                    .title{
                         font-size: 16px;
                    }
                    .text{
                         line-height: 1.8;
                         font-size: 12px;
                    }
               }
          }
     }
}

.text-list{
     float: none;
     width: 100%;
     margin-top: 0;
     .item{
     }
     .date{
          float: none;
          width: 100%;
          max-width: none;
          text-align: left;
          color:#999;
          font-size: 14px;
          b{
               float: left;
               margin-right: 10px;
               padding-bottom: 0;
               color:#999;
               font-size: 14px;
          }
     }
     .info{
          float: none;
          width: 100%;
          margin-top: 10px;
          .title{
               font-size: 15px;
          }
          .text{
               height: auto;
               line-height: 1.8;
               margin-top: 5px;
               color:#666;
               font-size: 13px;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 2;
               overflow: hidden;
          }
     }
}

.inside-banner{height: 130px;}

.crumbs{
     padding-top: 10px;
     padding-bottom: 10px;
     font-size: 13px;
}

.inside-left{
     float: none;
     width: 100%;
     .item{
          margin-bottom: 20px;
     }
     h3{
          height: 35px;
          line-height: 35px;
          padding-left: 10px;
          img{
               height: 20px;
               margin-top: (35px-20)/2;
               margin-right: 10px;
          }
     }
     ul{
          white-space: nowrap;
          overflow-x: scroll;
          font-size: 0;
          li{
               float: none;
               display: inline-block;
               a{
                    padding-left: 15px;
                    padding-right: 25px;
                    &:before{
                         top: (45px-5)/2;
                         left: 5px;
                         width: 5px;
                         height: 5px;
                    }
               }
          }
     }
}
.inside-right{
     float: none;
     width: 100%;
     margin-top: 40px;
     .h-title{
          font-size: 20px;
          span{font-size: 16px;}
     }
}

.list-product{
     .item{
          margin-top: 15px;
          .pic{
               height: 150px;
          }
          .info{
               padding-top: 5px;
               padding-bottom: 5px;
               padding-left: 10px;
               padding-right: 20px;
               span{
                    line-height: 20px;
                    &.xh{font-size: 14px;}
                    &.gn{font-size: 11px;}
               }
          }
     }
}

.paging{
     padding-top: 30px;
     padding-bottom: 60px;
     a,span{
          font-size: 13px;
          margin-left: 3px;
          margin-right: 3px;
          margin-top: 3px;
          margin-bottom: 3px;
     }
}

.footer{
     padding-top: 30px;
     .nav{
          line-height: 20px;
          a{
               margin-left: 5px;
               margin-right: 5px;
               font-size: 13px;
          }
     }
     .contact{
          margin-top: 25px;
          border-top: 1px #4b4e52 solid;
          .item{
               float: none;
               width: 100%;
               text-align: left;
               padding-top: 10px;
               padding-left: 10px;
               padding-right: 10px;
               padding-bottom: 10px;
               border-top: none;
               overflow: hidden;
               img{
                    float: left;
                    height: 20px;
                    margin-right: 10px;
               }
               p{
                    line-height: 20px;
                    margin-top: 0;
                    font-size: 13px;
                    br{display: none;}
               }
          }
     }
     .copyright{
          line-height: 15px;
          padding-top: 25px;
          padding-bottom: 15px;
          font-size: 13px;
     }
}
}

@media (max-width:320px){
.list-product{
     .item{
          float: none;
          width: 100%;
          .pic{
               height: auto;
               max-height: 300px;
          }
     }
}
}